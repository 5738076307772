import { graphql } from "gatsby"
import React, { useContext, useEffect } from "react"

import FeaturedCases from "../components/featured-cases"
import { InterfaceContext } from "../context/interface"
import { partitionCases } from "../utils/partition-cases"

const FeaturedCasesTemplate = ({ data, location, pageContext }) => {
  const { setTranslationLinks, setGridView } = useContext(InterfaceContext)
  useEffect(() => {
    setGridView(false) // Initialize as list view because we're showing a specific case
    setTranslationLinks(pageContext.canonicals)
  }, [])

  const partitioned = partitionCases(data.featured.cases)
  const keys = Object.entries(partitioned).map(([next]) => next)

  return (
    <FeaturedCases
      nav={data.navigation}
      dropup={data.dropup}
      subNav={data.subnav}
      footerNav={data.footerNavigation}
      caseKey="Stills"
      caseCategories={keys}
      cases={partitioned["Stills"]}
      data={data}
      location={location}
      pageContext={pageContext}
    />
  )
}

export default FeaturedCasesTemplate

export const featuredQuery = graphql`
  query StillCase($slug: String!, $featured_slug: String!, $locale: String!) {
    subnav: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Subnavigation" }
    ) {
      ...navigationFields
    }
    dropup: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Services" }
    ) {
      ...navigationFields
    }
    navigation: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Main" }
    ) {
      ...navigationFields
    }
    footerNavigation: contentfulNavigationMenu(
      node_locale: { eq: $locale }
      name: { eq: "Footer" }
    ) {
      ...footerNavigationFields
    }
    featured: contentfulFeaturedCases(slug: { eq: $featured_slug }) {
      id
      name
      slug
      cases {
        ...casePreviewFields
      }
    }
    currentCase: contentfulCase(slug: { eq: $slug }) {
      ...casePreviewFields
    }
  }
`
